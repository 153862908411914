






















































































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import accountStateRef from "@/apps/accounts/modules/store";
import {
  AboutMenu,
  ChangePasswordMenu,
  ContextMenu,
  KeluarMenu,
  Menu,
} from "@/apps/core/models/contextMenu";
import NasabahVM, { Nasabah } from "../models/nasabah";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import { isNasabah, isPengelola } from "@/apps/accounts/modules/store";
import { ToastProgrammatic as Toast } from "buefy";
import useViewModel from "@/apps/core/modules/useViewModel";
import UploadPhoto from "@/apps/core/modules/uploadPhoto";
import APP_CONFIG from "@/apps/core/modules/config";
import axios from "axios";
import { camelCase } from "lodash";
import router from "@/router";

export default defineComponent({
  name: "NasabahMe",
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
  },
  setup() {
    const paramsId =
      accountStateRef.me?.nasabah?.id ?? router.currentRoute.params.id;
    const nasabahVM = new NasabahVM();
    const { viewModelRef, instance } = useViewModel(paramsId, nasabahVM);
    const nasabah = instance as Nasabah;

    const adminMenus = [
      new Menu("Ubah Profil", "anggota-change"),
      new Menu("Penutupan", "summary-penutupan")
    ];
    const nasabahMenus = [
      new Menu("Ubah Profil", "nasabah-change"),
      new ChangePasswordMenu(),
      new KeluarMenu("Keluar", ""),
      new AboutMenu(),
    ];

    const adminContextMenu = new ContextMenu(adminMenus);
    const nasabahContextMenu = new ContextMenu(nasabahMenus);

    const fotoDataRef = ref(null);
    const ktpRef = ref(null);
    const kkRef = ref(null);
    const bukuAnggotaRef = ref(null);
    const jenisUpload = ref('');

    const canUpload = computed(
      () => isNasabah.value && nasabah.id === paramsId
    );
    const isUploading = ref(false);
    const uploadPhoto = new UploadPhoto("nasabah", (uploading: boolean) => {
      isUploading.value = uploading;
    });

    const saveFoto = async (url: string | null) => {
      const uploadDocUrl = `${APP_CONFIG.baseAPIURL}/nasabah/${paramsId}/`;
      const data = { foto: url };
      try {
        const response = await axios.patch(uploadDocUrl, data);
        nasabah.foto = response.data.foto;
        fotoDataRef.value = null;
        Toast.open("Foto berhasil di upload.");
      } catch {
        Toast.open("Foto gagal di upload.");
      }
    };

    const toCamelCaseObject = (json: Record<string, any>) => {
      const obj: Record<string, any> = {};
      for (const [field, value] of Object.entries(json)) {
        const cField = camelCase(field);
        obj[cField] = value;
      }
      return obj;
    };

    const saveDoc = async (url: string | null, jenis: string, isImage: boolean | null) => {
      const uploadDocUrl = `${APP_CONFIG.baseAPIURL}/nasabah/dokumen/`;
      const data = { berkas: url, jenis: jenis, 'is_image': isImage };
      try {
        const respData = await axios.post(uploadDocUrl, data);
        if (url) {
          const dokList = respData.data.dokList as Array<Record<string, any>>;
          nasabah.dokList = dokList.map((dok) => toCamelCaseObject(dok));
        }
        if (jenis === "KTP") {
          ktpRef.value = null;
        } else if (jenis === "Kartu Keluarga") {
          kkRef.value = null;
        } else {
          bukuAnggotaRef.value = null;
        }
        Toast.open("Berkas berhasil di upload.");
      } catch {
        Toast.open("Berkas gagal di upload.");
      }
    };

    const uploadProfile = (foto: File) => {
      jenisUpload.value = 'profile';
      uploadPhoto.requestAWS(foto, saveFoto);
    };

    const uploadBerkas = new UploadPhoto("dokumen", (uploading: boolean) => {
      isUploading.value = uploading;
    });

    const uploadDoc = (jenis: string) => {
      jenisUpload.value = jenis;
      const cbFunc = (url: string | null, isImage: boolean | null) => saveDoc(url, jenis, isImage);
      if (jenis === "KTP") {
        uploadBerkas.requestAWS(ktpRef.value, cbFunc);
      } else if (jenis === "Kartu Keluarga") {
        uploadBerkas.requestAWS(kkRef.value, cbFunc);
      } else {
        uploadBerkas.requestAWS(bukuAnggotaRef.value, cbFunc);
      }
    };

    const downloadFile = (url: string) => window.open(url, "_blank");

    const getFileIcon = (url: string) => {
      const re = /(?:\.([^.]+))?$/ as RegExp;
      const ext = re.exec(url);
      if (ext === null) return require("@/apps/core/assets/img/xlsx.png");
      return require(`@/apps/core/assets/img/${ext[1]}.png`);
    };

    let classContent = "mobile-content";
    if (!isNasabah.value) classContent += " has-background-white";

    return {
      // Data
      isUploading,
      bukuAnggotaRef,
      canUpload,
      classContent,
      adminContextMenu,
      nasabahContextMenu,
      kkRef,
      ktpRef,
      fotoDataRef,
      isNasabah,
      isPengelola,
      jenisUpload,
      nasabah,
      // imageUrl,
      viewModelRef,

      // Method
      downloadFile,
      getFileIcon,
      toUserDateFormat,
      uploadDoc,
      uploadProfile,
    };
  },
});
