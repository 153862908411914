












































import { defineComponent } from "@vue/composition-api";
import { isNasabah } from "@/apps/accounts/modules/store";
import ShuListVM from "@/apps/laporan/models/shuList";
import useListViewModel from "@/apps/core/modules/useListViewModel";

export default defineComponent({
  name: "SHUList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ShuListBox: () => import("@/apps/nasabah/components/ShuListBox.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const listVM = new ShuListVM();
    const composition = useListViewModel(listVM);
    const classContent = isNasabah.value
      ? "column no-padding is-full"
      : "column is-full";
    return {
      // Data
      classContent,

      // Composition
      ...composition,

      // Computed
      isNasabah,
    };
  },
});
