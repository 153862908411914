




































































































































































































import { computed, defineComponent, reactive, ref } from "@vue/composition-api";
import type { Ref } from "@vue/composition-api";
import accountStateRef from "@/apps/accounts/modules/store";
import NasabahVM, { Nasabah } from "../models/nasabah";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import { isNasabah, isPengelola } from "@/apps/accounts/modules/store";
import useViewModel from "@/apps/core/modules/useViewModel";
import APP_CONFIG from "@/apps/core/modules/config";
import axios from "axios";
import router from "@/router";
import PlainModel from "@/apps/core/models/plainModel";
import { Pinjaman } from "@/apps/pinjaman/models/pinjaman";
import { Penutupan } from "@/apps/pinjaman/models/penutupan";
import { formatRupiah } from "@/apps/core/modules/utils/text";

export default defineComponent({
  name: "SummaryPenutupanDetail",
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
  },
  setup() {
    const paramsId =
      accountStateRef.me?.nasabah?.id ?? router.currentRoute.params.id;
    const nasabahVM = new NasabahVM();
    const { viewModelRef, instance } = useViewModel(paramsId, nasabahVM);
    const nasabah = instance as Nasabah;
    const errorMessage = ref('');

    // const contextMenu = new ContextMenu(menus);

    class Simpanan extends PlainModel {
      pokok: string | null = null;
      nilaiPokok: string | null = null;
      wajib: string | null = null;
      nilaiWajib: string | null = null;
      sukarela: string | null = null;
      nilaiSukarela: string | null = null;
      penarikan: string | null = null;
      kewajiban: string | null = null;
      total: string | null = null;
    }

    const simpanan = reactive(new Simpanan());
    const pinjamanList: Ref<Array<Pinjaman>> = ref([]);

    const totalPinjaman = ref(0);
    const totalSisaPinjaman = ref(0);

    const selisih = computed(() => {
      const total = simpanan.total ? parseInt(simpanan.total) : 0;
      return total - (penutupan.totalBayar || 0);
    });
    const hasPijat = computed(() => {
      return pinjamanList.value.some(
        (el => el?.produk?.nama === 'PINJAMAN DARURAT (PIJAT)')
      );
    });
    const simpananCukup = computed(() => selisih.value >= 0);
    const bisaTutup = computed(() => {
      // return selisih.value >= 0;
      return nasabah.appuserIsActive && simpananCukup.value && !hasPijat.value;
    });

    const penutupan = reactive(new Penutupan());
    
    const biayaPenutupan = ref({});

    const hitungPenutupan = async (nasabahId: string) => {
      const apiUrl = `${APP_CONFIG.baseAPIURL}/nasabah/${nasabahId}/cek-pelunasan/`;
      const response = await axios.get(apiUrl);
      const adjObj = {bunga: response.data.bunga, totalBayar: response.data.total_bayar};
      // console.log(adjObj);
      Object.assign(penutupan, adjObj);
      // penutupan.totalBayar = response.data.total_bayar;
      // penutupan.bunga = response.data.bunga;
    }

    const summaryUrl = `${APP_CONFIG.baseAPIURL}/nasabah/${paramsId}/summary-penutupan/`;
    axios.get(summaryUrl).then((response) => {
      // console.log(response.data);
      // console.log(response.data.simpanan);
      // console.log(response.data.pinjaman);
      simpanan.loadData(response.data.simpanan)
      // Object.assign(simpanan, response.data.simpanan);
      for (const key in response.data.pinjaman) {
        // console.log(key);
        let pinjamanUrl = `${APP_CONFIG.baseAPIURL}/pinjaman/${key}/`;
        axios.get(pinjamanUrl).then((response) => {
          // console.log(response.data);
          let pinjaman = new Pinjaman();
          pinjaman.loadData(response.data);
          pinjamanList.value.push(pinjaman);
          totalPinjaman.value = totalPinjaman.value + (pinjaman.totalPinjaman || 0);
          totalSisaPinjaman.value = totalSisaPinjaman.value + (pinjaman.sisa || 0);
        });
        // const data = response.data.pinjaman[key];
        // data.id = key;
        // let pinjaman = new Pinjaman();
        // pinjaman.loadData(data);
        // pinjamanList.push(pinjaman);
        
        // totalSisaPinjaman.value = totalSisaPinjaman.value + parseInt(pinjaman.sisa)
        hitungPenutupan(paramsId);
      }
      // Object.assign(simpanan, response.data.simpanan);
      // Object.assign(pinjaman, response.data.pinjaman);
    });


    let classContent = "mobile-content";
    if (!isNasabah.value) classContent += " has-background-white";

    const cancel = () => router.go(-1);

    const save = () => {
      errorMessage.value = "";
      const keluarUrl = `${APP_CONFIG.baseAPIURL}/nasabah/${paramsId}/keluar/`;
      axios.patch(keluarUrl).then((response) => {
        console.log(response.data);
        nasabah.appuserIsActive = false;
      }).catch((error) => {
        error.response.data.forEach((el: any) => {
          errorMessage.value += el["non_field_errors"][0];
          console.log(el["non_field_errors"][0]);
        });
        console.log(error.response.data)
      });
    };

    return {
      // Data
      biayaPenutupan,
      classContent,
      // contextMenu,
      errorMessage,
      hasPijat,
      isNasabah,
      isPengelola,
      nasabah,
      // imageUrl,
      penutupan,
      pinjamanList,
      simpanan,
      simpananCukup,
      totalSisaPinjaman,
      viewModelRef,

      bisaTutup,
      selisih,

      // Method
      cancel,
      formatRupiah,
      save,
      toUserDateFormat,
    };
  },
});
