












import { defineComponent, onMounted, reactive } from "@vue/composition-api";
// import { Pinjaman } from "@/apps/pinjaman/models/pinjaman";
import { NasabahSummaryVM } from "../models/summary";

export default defineComponent({
  name: "NasabahHome",
  components: {
    UserInfo: () => import("../components/UserInfo.vue"),
    FiturSection: () => import("../components/FiturSection.vue"),
    PinjamanInfo: () => import("../components/PinjamanInfo.vue"),
    SimpananInfo: () => import("../components/SimpananInfo.vue"),
  },
  setup() {
    const summaryVM = new NasabahSummaryVM();
    const viewModelRef = reactive(summaryVM);
    onMounted(async () => {
      viewModelRef.isLoading = true;
      await viewModelRef.fetch();
      viewModelRef.isLoading = false;
    });

    return {
      viewModelRef,
      summary: viewModelRef.instance,
    };
  },
});
