























































































































































































































import { defineComponent } from "@vue/composition-api";
import accountStateRef, {
  isNasabah,
  isNewNasabahUser,
  isPengelola,
} from "@/apps/accounts/modules/store";
import NasabahFM from "../models/nasabahForm";
import MeVM, { Me } from "@/apps/accounts/models/me";
import router from "@/router";
import { SaveContext } from "@/apps/core/models/formModel";
import useFormModel from "@/apps/core/modules/useFormModel";
import { isEmail } from "@/apps/core/modules/utils/text";
import { Nasabah } from "../models/nasabah";
import { listVM } from "@/apps/pengelola/modules/store";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "NasabahForm",
  props: ["headerText"],
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const requiredFields = ["nama", "email"];
    const nonRequiredFields = [
      "panggilan",
      "tglLahir",
      "agama",
      "gender",
      "noHp",
      "noRekening",
    ];
    const nasabahId = accountStateRef.me.nasabah?.id;
    const paramsId = nasabahId ?? router.currentRoute.params.id;
    const form = new NasabahFM(requiredFields, nonRequiredFields);
    const composition = useFormModel(form, paramsId);
    const nasabah = composition.instance as Nasabah;
    if (isPengelola.value) {
      composition.formRef.requiredFields.push("statusKaryawan");
      composition.formRef.requiredFields.push("noAnggota");
      composition.formRef.nonRequiredFields.push("unitKerja");
      composition.formRef.nonRequiredFields.push("jabatan");
      composition.formRef.nonRequiredFields.push("appuserIsActive");
      composition.formRef.resetErrorMap(router.currentRoute.params.id == null);
    }

    const validateEmail = () => {
      const email = nasabah.email;
      if (email) {
        if (!isEmail(email)) {
          form.errorMap.email = "Isikan dengan format email.";
          return;
        }
      } else {
        nasabah.email = null;
      }
      form.validate("email");
    };

    const saveNasabah = async () => {
      const isCreate = !paramsId;
      const saveContext = isCreate ? SaveContext.Create : SaveContext.Update;
      await form.save(saveContext, !isCreate);
      if (form.getIsValid().value) {
        if (isNasabah.value) {
          const meVM = new MeVM(accountStateRef.me as Me);
          await meVM.fetch();
          // router.replace({
          //   name: "nasabah-me",
          // });
        } else {
          if (isCreate) {
            router.replace({
              name: "nasabah-detail",
              params: { id: nasabah.id ?? "" },
            });
          }
          listVM.setRefreshRequest("nasabah");
        }
      }
    };

    return {
      // Data
      isNasabah,
      nasabahId,

      // Computed
      isNewNasabahUser,

      // Composition
      ...composition,

      // Method
      saveNasabah,
      validateEmail,
    };
  },
});
