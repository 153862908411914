import { API } from "@/apps/core/api";
import { ToastProgrammatic as Toast } from "buefy";
import axios from "axios";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";
import { Nasabah } from "@/apps/nasabah/models/nasabah";


class NasabahSummary extends PlainModel {
  month: string | null = null;
  total: number | null = null;
  pinjaman: Record<string, any> | null = null;
  simpanan: Record<string, any> | null = null;
}

class NasabahSummaryAPI extends API {
  static modelPath = "/nasabah/summary-dashboard/";

  constructor() {
    super(NasabahSummaryAPI.modelPath);
  }
}

class NasabahSummaryVM extends ViewModel {
  constructor() {
    super(new NasabahSummaryAPI(), new NasabahSummary());
  }

  async fetch(): Promise<void> {
    try {
      const url = this.api.getAPIUrl();
      const response = await axios.get(url);
      this.instance.loadData(response.data);
    } catch (error) {
      Toast.open("Data gagal dimuat.");
    }
  }
}

class Summary extends PlainModel {
  nasabah: Nasabah | null = null;
  nilaiWajib: number | null = null;
  nilaiSukarela: number | null = null;
  simpananWajib: number | null = null;
  simpananSukarela: number | null = null;
}

class SummaryAPI extends API {
  static modelPath = "/nasabah/summary/";

  constructor() {
    super(SummaryAPI.modelPath);
  }
}

export default class SummaryVM extends ViewModel {
  constructor() {
    super(new SummaryAPI(), new Summary());
  }
}


export { NasabahSummary, NasabahSummaryVM };